.card,
.nav-tabs,
.nav-link {
    border: none !important;
}

.nav-item > a {
    border-top-left-radius: 0.8rem !important;
    border-top-right-radius: 0.8rem !important;
}
