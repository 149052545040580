@import "../utils/_variables";

.badge {
    font-weight: $font-normal;
    padding: 5px 8px !important;
}

// .badge-primary {
//     color: $color-text-dark;

//     &:hover {
//         background-color: darken($main-background, 10%);
//         border-color: darken($main-background, 10%);
//         color: $color-text-dark;
//     }

//     &:focus {
//         border-color: $main-background !important;
//         background-color: $main-background !important;
//         box-shadow: 0 0 0 0.2rem rgba($main-background, 0.4) !important;
//         color: $color-text-dark;
//     }
// }

.badge-transparent {
    color: $color-text-dark;
    background-color: transparent;
    border: 1px solid rgba($color-text-dark, 0.4) !important;

    &:focus {
        border-color: $color-text-dark !important;
        background-color: #fff !important;
        box-shadow: 0 0 0 0.2rem rgba($color-text-dark, 0.4) !important;
    }
}

.badge-success {
    background-color: $color-green;
    border-color: $color-green;

    &:focus {
        border-color: $color-green !important;
        background-color: $color-green !important;
        box-shadow: 0 0 0 0.2rem rgba($color-green, 0.4) !important;
    }
}

.badge-warning {
    color: $color-text-dark;
    background-color: $color-brand-1;
    border-color: $color-brand-1;

    &:focus {
        border-color: $color-button-warning !important;
        background-color: $color-button-warning !important;
        box-shadow: 0 0 0 0.2rem rgba($color-button-warning, 0.4) !important;
    }
}
