@import "../utils/_variables";

html,
body {
    height: 100%;
}

body {
    touch-action: manipulation;
    height: 100%;
    width: 100%;
    overflow: auto;
    color: $color-text-dark !important;
    font-family: $font-family;
    font-weight: $font-light !important;
    background: $main-background;
}

#root {
    height: 100%;
}

#outer-container {
    height: 100%;
    overflow: hidden;
}

.main {
    width: 100%;
    overflow: auto;
}

.app-body {
    padding-top: 63px;
}

#page-wrap {
    position: relative;
    z-index: 0;
    contain: layout;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 1.5rem 1.75rem;
    height: 100%;
}

.safari-fix #page-wrap {
    position: fixed;
    overflow: hidden;
    -webkit-overflow-scrolling: auto;
}

.no-transition,
.no-transition * {
    transition-property: none !important;
    transform: none !important;
    animation: none !important;
}

main {
    height: 100%;
    overflow: auto;

    &.error-page {
        padding-top: $spacing-double;
    }
}

.top-spacing {
    margin-top: $spacing;
}

.top-spacing-double {
    margin-top: $spacing-double;
}

.top-spacing-third {
    margin-top: $spacing-third;
}

.top-spacing-half {
    margin-top: $spacing-half;
}

.flex-centered {
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-content {
    border: none;
}

img {
    max-width: 100%;
    height: auto;
}

a,
button {
    &::-moz-focus-inner {
        border: 0;
    }

    &:focus {
        outline: 0;
    }

    cursor: pointer;
}

hr {
    border-color: #ccc;
}

section {
    figure {
        margin-bottom: $spacing;

        figcaption {
            display: block;
            margin-bottom: $spacing-half;
        }

        img {
            width: 100%;
        }
    }
}

article section {
    margin-bottom: $spacing-double;
}

blockquote {
    margin: 0;
    padding-left: $spacing-half;
    border-left: 0.5rem $color-divider solid;
}

.cursor-pointer {
    cursor: pointer;
}

.text-muted {
    color: #a4a4a4 !important;
}

.text-strong {
    font-weight: 500;
}

label {
    margin-bottom: 0 !important;
}

.text-light {
    color: #eeeeee !important;
}

.font-weight-semi-bold {
    font-weight: 600 !important;
}

.underline-hover:hover {
    cursor: pointer;
    text-decoration: underline;
}

.pointer-events-none {
    pointer-events: none !important;
}

.pointer-events-all {
    pointer-events: all !important;
}

/* width */
::-webkit-scrollbar {
    height: 8px;
    width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
    // background-color: lighten($color-text-dark, 10%);
    background-color: #d8d8d8;
    border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background-color: #cacaca;
}
