//-------------------------------------//
// MEDIA QUERIES

$media-lg: 1200px;
$media-md: 992px;
$media-sm: 768px;
$media-xs: 480px;

//-------------------------------------//
// DIMENSIONS

// navmenu height
$height-navmenu: 60px;

// sidebar
$sidebar-width: 300px;

// border-radius
$border-radius-base: 0;
$border-radius-small: 3px;
$border-radius-medium: 5px;
$border-radius-large: 10px;

//-------------------------------------//
// COLORS

// color: background
$color-background: #fff;
$main-background: #fbf8f8;

// color: buttons
$color-button-warning: #ffc107;

// color: brand
$color-light: #ffffff;
$color-brand-1: #ffcc78;
$color-brand-2: #464646;
$color-brand-3: #efefef;
$color-brand-4: #f6f0f0;
$color-brand-5: #ebedef;
$color-green: #94bc76;

// font colors
$color-text-dark: #333f6a;
$color-text-darker: #25325f;
$color-text-light: $color-light;
$color-semi-muted: #a4a4a4;

// color: font
$color-font-base: $color-brand-2;

$color-primary: $color-brand-1;
// $color-secondary: ;
// $color-success: ;
// $color-warning: ;
// $color-info: ;
// $color-danger: ;
// $color-light: ;

// colors: links
$color-link: $color-text-dark;
$color-link-hover: lighten($color-text-dark, 25%);
$color-link-focus: lighten($color-text-darker, 5%);

// color: dividers/borders
$color-divider: #efefef;

// color: modal background
$color-modal-bg: #f5f7f9;

// color: shadow
$color-shadow: rgba(0, 0, 0, 0.11);

//-------------------------------------//
// FONTS

// fonts: base styles
$font-family: "Poppins", arial, sans-serif;
$font-family-1: "Source Sans Pro", "Helvetica Neue", helvetica, arial,
    sans-serif;
$font-size-base: 1rem;

// fonts: weights
$font-light: 300;
$font-normal: 400;
$font-medium: 600;
$font-bold: 700;

//-------------------------------------//
// SPACING

$spacing: 1.75rem;
$spacing-double: $spacing * 2;
$spacing-third: $spacing * 3;
$spacing-half: $spacing / 2;

//-------------------------------------//
// Z:INDEX

$zindex-header: 1000;
$zindex-dropdown: 1100;
$zindex-notify: 1300;
$zindex-modal: 1400;
$zindex-overlay: 100;
