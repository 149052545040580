@import "../utils/_variables";

.dropdown {
    &-menu {
        border: 0;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.17) !important;
    }

    &-item {
        border-bottom: 0;
        color: $color-text-dark;
    }
}

.dropdown-item {
    padding: 5px 20px;

    &.active {
        background-color: $color-text-dark;
    }

    &:active {
        background-color: $color-text-dark;
    }
}
