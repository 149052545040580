@import "../utils/_variables";

.table {
    color: $color-text-dark;

    th {
        border: 0 !important;
        font-weight: $font-medium;
        font-size: 12px;
    }

    td {
        vertical-align: middle !important;
    }
}
