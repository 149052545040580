@import "../utils/_variables";

.login,
.reset-password,
.forgot-password {
    background: $main-background;
    height: 100vh;

    a {
        font-size: 12px;
    }

    input {
        border: 2px solid #edf0f3 !important;
        font-weight: 400;
        font-size: 16px;
        padding: 10px 20px;
        height: 55px;
    }

    input::placeholder {
        color: #bbbbbb;
        font-weight: 400;
    }

    input:focus {
        box-shadow: none !important;
        border-color: $color-brand-1 !important;
    }

    button {
        background-color: $color-brand-1 !important;
        font-weight: lighter;
    }

    .form-container {
        border-top-left-radius: $border-radius-large;
        border-top-right-radius: $border-radius-large;
        border-bottom-left-radius: $border-radius-large;
        border-bottom-right-radius: $border-radius-large;
        width: 350px;

        @include screen-xs {
            width: 75%;
        }
    }
}
