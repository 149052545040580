@import "./vendors/bootstrap";
@import "./utils/variables";
@import "./utils/mixins";
@import "./base/base";
@import "./base/typography";

@import "./components/button";
@import "./components/badge";
@import "./components/table";
@import "./components/dropdown";
@import "./components/card";
@import "./components/modal";
@import "./components/tabs";
@import "./components/tooltip";
@import "./components/forms";
@import "./components/externalForms";

// Import Font Awesome Icons Set
@import "~font-awesome/css/font-awesome.min.css";
