@import "../utils/_variables";

.estimates-logs-tooltip {
    color: $color-text-dark !important;
    background-color: #fff !important;
    box-shadow: 0 0.125rem 0.45rem rgba(0, 0, 0, 0.15) !important;
    pointer-events: auto !important;
    padding: 0 !important;

    &.place-top {
        &:after {
            border-top-color: #fff !important;
            border-top-style: solid !important;
            border-top-width: 6px !important;
        }
    }

    &:hover {
        visibility: visible !important;
        opacity: 1 !important;
        color: $color-text-dark !important;
    }
}
