@import "../utils/_variables";

.btn-brand {
    background-color: $color-text-dark;
    border-color: $color-text-dark;

    &:hover {
        background-color: darken($color-text-dark, 10%);
        border-color: darken($color-text-dark, 10%);
    }

    &:focus {
        border-color: $color-text-dark !important;
        background-color: $color-text-dark !important;
        box-shadow: 0 0 0 0.2rem rgba($color-text-dark, 0.4) !important;
        color: $color-text-light;
    }
}

.btn-primary {
    background-color: $main-background;
    border-color: $main-background;
    color: $color-text-dark;

    &:hover {
        background-color: darken($main-background, 10%);
        border-color: darken($main-background, 10%);
    }

    &:focus {
        border-color: $main-background !important;
        background-color: $main-background !important;
        box-shadow: 0 0 0 0.2rem rgba($main-background, 0.4) !important;
    }
}

.btn-warning {
    background-color: $color-brand-1 !important;
    border-color: $color-brand-1 !important;
    color: $color-text-dark !important;

    &:hover {
        background-color: darken($color-brand-1, 10%) !important;
        border-color: darken($color-brand-1, 10%) !important;
        color: $color-text-dark !important;
    }

    &:focus {
        border-color: $color-brand-1 !important;
        background-color: $color-brand-1 !important;
        box-shadow: 0 0 0 0.2rem rgba($color-brand-1, 0.4) !important;
        color: $color-text-dark !important;
    }
}

.btn-light {
    background-color: $color-brand-5 !important;
    border-color: $color-brand-5 !important;
    color: $color-text-dark !important;

    &:hover {
        background-color: darken($color-brand-5, 10%) !important;
        border-color: darken($color-brand-5, 10%) !important;
        color: $color-text-dark !important;
    }

    &:focus {
        border-color: $color-brand-5 !important;
        background-color: $color-brand-5 !important;
        box-shadow: 0 0 0 0.2rem rgba($color-brand-5, 0.4) !important;
        color: $color-text-dark !important;
    }
}
